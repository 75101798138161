import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";
import { PayableStatus } from "./PayableStatus";

const classes = {
    tableContainer: {
        minHeight: '150px',
        position: 'relative'
    },
    tableHead: {
        '& .MuiTableCell-root': {
            color: '#64748b',
            fontWeight: 'bold',
            paddingBlock: '8px',
            paddingInline: '0px',
        }
    },
    tableBody: {
        '& .MuiTableCell-root': {
            paddingInline: '0px',
        }
    },
    bolder: {
        fontWeight: 'bolder'
    },
    status: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    noRecords: {
        position: 'absolute',
        top: `60%`,
        left: '45%'
    }

} as const;

export const BuildingInvoicesList = ({ buildingFinance }: { buildingFinance: any }) => {

    const { t } = useTranslation();

    let finance = buildingFinance || {}

    // let buildingPayable = buildingFinance && buildingFinance.payable && buildingFinance.payable.reduce((acc: any, x: any) => acc + x.price, 0)
    // let inflowSum = buildingFinance && buildingFinance.inflow && buildingFinance.inflow.reduce((acc: any, x: any) => acc + x.amount, 0)
    // let outflowSum = buildingFinance && buildingFinance.outflow && buildingFinance.outflow.reduce((acc: any, x: any) => acc + x.amount, 0)
    // let sumFundsPayables = (buildingFinance && buildingFinance.fund && buildingFinance.fund.reduce((acc: any, x: any) => acc + (x.receivable || 0), 0) || 0)


    // let fullBalance = buildingFinance && inflowSum && buildingPayable && (inflowSum - buildingPayable - sumFundsPayables)
    // let cashBalance = buildingFinance && buildingFinance.balance
    // let calculatedCashBalance = buildingFinance && (inflowSum - outflowSum)

    let outflows = buildingFinance ? buildingFinance.outflow : []

    let fundMap = (finance.fund || []).reduce((a: any, x: any) => ({ ...a, [x._id]: x.name }), {})
    let fundOutflows = (outflows || []).filter((x: any) => !!x.fundId)
    fundOutflows = fundOutflows.map((x: any) => ({ name: x.reason, price: x.amount, accounting: fundMap[x.fundId], created: x.date, type: "fund" }))

    let payables = buildingFinance ? buildingFinance.payable : []

    payables.push(...fundOutflows)

    payables.sort((a: any, b: any) => Date.parse(a.created) - Date.parse(b.created))

    return (
        <Card>
            <TableContainer sx={classes.tableContainer}>
                <Table size="medium">
                    <TableHead>
                        <TableRow sx={classes.tableHead}>
                            <TableCell align="left">{t("Payables")}</TableCell>
                            <TableCell align="center">{t("Date")}</TableCell>
                            <TableCell align="center">{t("Accounting")}</TableCell>
                            <TableCell align="center">{t("Total")}</TableCell>
                            <TableCell align="center">{t("Status")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payables.map((payable: any) => (
                            <TableRow key={payable._id} sx={classes.tableBody}>
                                <TableCell align="left">{payable.name} </TableCell>
                                <TableCell align="center">{t("formatDate", { date: new Date(payable.created) })}</TableCell>
                                <TableCell align="center">{t(payable.accounting)} </TableCell>
                                <TableCell align="center" sx={classes.bolder}>{t("priceFormat", { num: (payable.unitPrice || payable.price) })}</TableCell>
                                <TableCell sx={classes.status}><PayableStatus status={payable.status === "paid" || payable.type === "fund"} /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {payables.length === 0 && <Typography sx={classes.noRecords} >{t('No records')}</Typography>}
            </TableContainer>
        </Card>
    );
}