import React, { ReactNode } from "react"
import './EntryPage.css';

export const EntryPage = ({children}: {children?: ReactNode}): JSX.Element => {

    return (
        <div className="RegisterWrapper">
            <div className="Logo">
                <img src={process.env.PUBLIC_URL + '/assets/logo-svg.svg'}  alt='logo'/> 
            </div>
            <div className="Form">
                {children}
            </div>
        </div>
    )
}