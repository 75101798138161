import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { EntryPage } from './components/entry-page/EntryPage';
import { RegisterForm } from './components/register/RegisterForm';
import { LoginForm } from './components/LoginForm';
import { Application } from './components/Application';
import { createTheme, ThemeProvider } from '@mui/material';
import { ProtectedRoute } from './components/ProtectedRoute';

const theme = createTheme({
	palette: {
		primary: {
			main: 'rgb(19, 71, 175)'
		}
	},
	typography: {
		fontFamily: 'Inter',
		body1: {
			fontSize: '1rem',
		},
		allVariants: {
			color: 'rgb(39, 49, 58)'
		}
	},
});


function App() {
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Routes>
					<Route path='register/:buildingId' element={<EntryPage children={<RegisterForm />} />} />
					<Route path='register/error' element={<EntryPage children={<div>Invalid invitational link - screen to be implemented</div>} />} />
					<Route path='login' element={<EntryPage children={<LoginForm />} />} />
					<Route path='tenant/*' element={<ProtectedRoute><Application /></ProtectedRoute>} />
					<Route path='*' element={<Navigate replace to="login" />} />
				</Routes>
			</Router>
		</ThemeProvider>
	);
}

export default App;
