import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { LogoutIcon } from "../icons/LogoutIcon";
import { UserIcon } from "../icons/UserIcon";
import { subMenu } from "./SideMenuData";
import "./SideMenu.css";
import { useLocation } from "react-router-dom";
import { SideMenuItem } from "./SideMenuItem";
import { useTranslation } from "react-i18next";

const classes = {
    logo: {
        width: '75%',
        alignSelf: 'center'
    },
    accountInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5px'
    },
    menuText: {
        color: 'rgb(204, 217, 232)',
        margin: '0px',
    },
    boldText: {
        fontWeight: 'bolder',
    },
    signOut: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        gap: '10px',
        alignItems: 'center'
    }
} as const;

export const SideMenu = ({ active, toggleSideMenu, email, firstName }: { active: boolean, toggleSideMenu: (open: boolean) => void, email: string, firstName: string }) => {

    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState(location.pathname.split('/').at(-1)!);
    const { t } = useTranslation();

    const changeSelectedItem = (item: string) => {
        setSelectedItem(item);
        toggleSideMenu(false);
    };

    return (
        <div className={active ? 'side-menu active' : 'side-menu'}>
            <div className="content">
                {/* TODO Create component for logo as well. */}
                <img src={process.env.PUBLIC_URL + '/assets/logo-svg.svg'} alt='logo' style={classes.logo} />
                <Box sx={classes.accountInfo}>
                    <UserIcon />
                    <Typography sx={{ ...classes.menuText, ...classes.boldText }}>{firstName}</Typography>
                    <Typography sx={classes.menuText}>{email}</Typography>
                    <Box sx={classes.signOut}>
                        <Typography style={classes.menuText}>{t('Sign out')}</Typography>
                        <LogoutIcon />
                    </Box>
                </Box>
                {subMenu.map((menu, index) => (
                    <SideMenuItem key={index} content={menu} selectedItem={selectedItem} changeSelectedItem={changeSelectedItem} />
                ))}
            </div>
            <div className="transparent" onClick={() => { toggleSideMenu(false) }}></div>
        </div>
    );
}