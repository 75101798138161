// console.log("process.env.REACT_APP_USE_DEV")
// console.log(process.env.REACT_APP_USE_DEV)
// let API_ADDRESS
// if (process.env.REACT_APP_USE_DEV) {
//     // API_ADDRESS = API_ADDRESS = "http://localhost:3001"
// } else {
//     API_ADDRESS = "/api"
// }
const API_ADDRESS = "/api"

export const JWT_TENANT = "jwtt"
export const JWT_MANAGER = "jwtm"


function fetchRequestBase(jwt, api_prefix, address, callback, dispatch, method = "GET", object = undefined, isDownload = false) {
    let requestInit = {
        method,
        headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem(jwt)
        }
    }

    if (!isDownload && object) {
        requestInit["body"] = JSON.stringify(object)
    }

    return fetch(API_ADDRESS + api_prefix + address, requestInit)
        .then(res => {
            //NOTE: cascade promise is used in order to get both res.body and res status in same processing phase.
            let promise;
            if (!isDownload) {
                promise = res.json()
            }
            else {
                promise = res.blob()
            }
            promise.then(json => {
                //TODO: unify error handling
                //TODO: verify status 
                if (!res.ok) {
                    if (typeof (json) === "string") {
                        json = { errorMessage: json }
                    }
                    json.statusText = res.statusText
                    json.status = res.status
                    console.error(json)
                    // if (json.status === "error") {}
                    if (callback) { callback(json, null, dispatch) }
                    return
                }
                if (!isDownload) {
                    if (callback) { callback(null, json, dispatch) };
                }
                else {
                    // 1. Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([json]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', object);
                    // 2. Append to html page
                    document.body.appendChild(link);
                    // 3. Force download
                    link.click();
                    // 4. Clean up and remove the link
                    link.parentNode.removeChild(link);

                    if (callback) { callback(null, json, dispatch) };
                }
            })
                .catch(error => {
                    // dispatch(fetchProductsFailure(error))
                    // console.error("ERR fetch")
                    console.error(error)
                    if (callback) { callback(error, null, dispatch) }
                });
        })
        .catch(error => {
            // dispatch(fetchProductsFailure(error))
            // console.error("ERR fetch")
            console.error(error)
            if (callback) { callback(error, null, dispatch) }
        });
}

export function fetchRequestManager(address, callback, dispatch, method = "GET", object = undefined, isDownload = false) {
    return fetchRequestBase(JWT_MANAGER, "/manager", address, callback, dispatch, method, object, isDownload)
}
export function fetchRequestTenant(...args) { return fetchRequestBase(JWT_TENANT, "/tenant", ...args) }