import { Box } from "@mui/material";
import jwtDecode from "jwt-decode";
import React from "react";
import { useEffect, useState } from "react"
import { getBuilding, getTenantInfo } from "../actions/data-manager";
import { JWT_TENANT } from "../actions/network-manager"
import { ApplicationTab } from "./ApplicationTab";
import { NavBar } from "./NavBar/NavBar";
import { SideMenu } from "./SideMenu/SideMenu";

const classes = {
    mainWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    rightSideWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    }
} as const;

export const getBuildingId = () => {
    let res: any = jwtDecode(localStorage.getItem(JWT_TENANT)!);
    return res.bl[0].id;
}

export const ApplicationContext = React.createContext<any>(null);

export const Application = () => {

    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const [tenantInfo, setTenantInfo] = useState<any>();
    const [selectedUnit, setSelectedUnit] = useState<any>('');
    const [buildingId] = useState(getBuildingId());
    const [building, setBuilding] = useState<any>();

    const toggleSideMenu = (open: boolean) => {
        setSideMenuOpen(open);
    }

    const handleUnitChange = (unitId: any) => {
        setSelectedUnit(building.units.filter((unit: any) => unit._id === unitId)[0]);
    };

    useEffect(() => {
        getBuilding(buildingId, (err: any, data: any) => {
            setBuilding(data);
        });
    }, [buildingId]);

    useEffect(() => {
        if (!!building)
            getTenantInfo((err: any, data: any) => {
                setTenantInfo(data);
                setSelectedUnit(building.units.filter((unit: any) => unit._id === data.buildings[0].units[0])[0] || '');
            });
    }, [building]);

    return (
        <ApplicationContext.Provider value={{ tenantInfo }}>
            {!!building && !!tenantInfo &&
                <Box sx={classes.mainWrapper}>
                    <SideMenu active={sideMenuOpen} toggleSideMenu={toggleSideMenu} email={tenantInfo.email} firstName={tenantInfo.firstName} />
                    <Box sx={classes.rightSideWrapper}>
                        <NavBar toggleSideMenu={toggleSideMenu} units={building.units.filter((unit: any) => tenantInfo.buildings[0].units.includes(unit._id))} selectedUnit={selectedUnit} onUnitChange={handleUnitChange} />
                        <ApplicationTab building={building} selectedUnit={selectedUnit} />
                    </Box>
                </Box>
            }
        </ApplicationContext.Provider>
    )
}