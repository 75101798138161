import './Icons.css';

export const HamburgerIcon = ({toggleSideMenu}: {toggleSideMenu: (open: boolean) => void}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className='hamburger-menu' fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} width={'25px'} onClick={() => {toggleSideMenu(true)}}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
    );
}

