import { SubMenu } from "../../internal-types";
import { ActivityIcon } from "../icons/ActivityIcon";
import { BuidlingInvoicesIcon } from "../icons/BuildingInvoicesIcon";
import { DocumentIcon } from "../icons/DocumentIcon";
import { HomeIcon } from "../icons/HomeIcon";
import { InvoicesIcon } from "../icons/InvoicesIcon";
import { LanguageIcon } from "../icons/LanguageIcon";
import { NotificationIcon } from "../icons/NotificationIcon";
import { SettingsIcon } from "../icons/SettingsIcon";
import { VotingIcon } from "../icons/VotingIcon";


export const subMenu: SubMenu[] = [
    {
        title: 'Main menu',
        items: [
            { title: 'Overview', icon: <HomeIcon />, path: 'overview' },
            { title: 'Notifications', icon: <NotificationIcon />, path: 'notifications' },
            { title: 'Issues', icon: <ActivityIcon />, path: 'issues' },
            { title: 'Voting', icon: <VotingIcon />, path: 'voting' }
        ]
    },
    {
        title: 'Accounts/Finance',
        items: [
            { title: 'My account', icon: <InvoicesIcon />, path: 'invoices' },
            { title: 'Building finance', icon: <BuidlingInvoicesIcon />, path: 'building-invoices' },
            { title: 'Documents', icon: <DocumentIcon />, path: 'documents' }]
    },
    {
        title: 'Application',
        items: [
            { title: 'Settings', icon: <SettingsIcon />, path: 'settings' },
            { title: 'English', icon: <LanguageIcon /> }]
    }

];