import { fetchRequestManager, fetchRequestTenant } from './network-manager'
import { fetchBuildingsSuccess, genericAction, UNAUTHENTICATED } from './index'


export function fetchBuildings() {
    return dispatch => {
        fetchRequestManager("/buildings", (err, data, dispatch) => {
            if (err) {
                if (err.status === 401) {
                    dispatch(genericAction(UNAUTHENTICATED))
                }
                return;
            } else {
                dispatch(fetchBuildingsSuccess(data));
            }
        }
            , dispatch, "GET" //,null, false
        )
    }
}

////////////////////////////////////////
//TODO: handle this, it should not go over reducer
export function postNewBuilding(data) {
    fetchRequestManager("/buildings", null, null, "POST", data)
    //TODO: !!! Add callback that will ADD_BUILDING, not vice versa as it is now
}

export function fetchTemplate(buildingId) {
    fetchRequestManager("/b/" + buildingId + "/templating/engine", null, null, 'POST', 'pojedinacno glasanje.pdf', true)
    //TODO: !!! Add callback that will CREATE_DOCUMENTS, not vice versa as it is now
}

export function fetchTemplateMeetingVoting(buildingId) {
    fetchRequestManager("/b/" + buildingId + "/templating/meeting-voting", null, null, 'POST', 'grupno glasanje.pdf', true)
    //TODO: !!! Add callback that will CREATE_DOCUMENTS, not vice versa as it is now
}

export function fetchTemplateLatestVoting(buildingId) {
    fetchRequestManager("/b/" + buildingId + "/templating/latest-voting", null, null, 'POST', 'Izvestaj.pdf', true)
    //TODO: !!! Add callback that will CREATE_DOCUMENTS, not vice versa as it is now
}

////////////////////////



export function downloadLatestInvoice(buildingId) {
    //TODO: consider pros/cons over reducer actions
    return dispatch => {
        fetchRequestManager("/b/" + buildingId + "/templating/invoice/print-latest" , null, null, 'GET', 'invoice.pdf', true)
        // return downloadRequest('GET', API_ADDRESS + "/finance/invoice/print-latest/" + buildingId, 'invoice.docx')
    }
}

export function postObligation(buildingId, name, link, price, accounting, callback) {
    return dispatch => {
        fetchRequestManager("/finance/obligation",
            (err, data, dispatch) => {
                if (callback) { callback() }
                //TODO: consider this
                fetchBuildings()(dispatch);
            },
            dispatch,
            "POST",
            {
                "buildingId": buildingId,
                "name": name,
                "price": price,
                "link": link,
                accounting
            }
        )
    };
}


export function postInvoiceConfirmation(buildingId, periodStart, periodEnd) {
    return dispatch => {
        fetchRequestManager("/finance/invoice-confirmation/" + periodStart + "/" + periodEnd,
            (err, data, dispatch) => {
                //TODO: add callback
                // if (callback) { callback() }
                //TODO: consider this
                fetchBuildings()(dispatch);
            },
            dispatch,
            "POST",
            {buildingId}
        )
    };
}
//////////////////////////////
//Thunks 

function thunkRequestBase(fetchFN, address, object, callback, method = "POST", getBuildings = true) {
    return dispatch => {
        fetchFN(
            address,
            (err, data, dsp) => {
                if (callback) { callback(err, data, dsp) };
                if (getBuildings) {
                    fetchBuildings()(dsp);
                }
            },
            dispatch, method, object)
    }
}

export function thunkRequestTenant(address, object, callback, method = "POST", getBuildings = false) {
    return thunkRequestBase(fetchRequestTenant, address, object, callback, method, getBuildings)
}

export function thunkRequestManager(address, object, callback, method = "POST", getBuildings = true) {
    return thunkRequestBase(fetchRequestManager, address, object, callback, method, getBuildings)
}
/////////////////////////////

export function postNewNotification(buildingId, title, description, callback) {
    return thunkRequestManager(
        '/notifications',
        {
            buildingId,
            title,
            description
        },
        callback)
}

export function putNotification(buildingId, notificationId, title, description, callback) {
    return thunkRequestManager(
        '/notifications',
        {
            buildingId,
            notificationId,
            title,
            description
        },
        callback,
        "PUT")
}

export function deleteNotification(buildingId, notificationId) {
    return thunkRequestManager('/notifications',
        {
            buildingId,
            notificationId
        },
        null
        , "DELETE"
    )
}

export function postNewIsssue(buildingId, title, description, callback) {
    return thunkRequestManager(`/b/${buildingId}/issues`,
        {
            title,
            description
        }, callback)
}

export function postNewSolution(buildingId, issueIndex, name, description, price, callback) {
    return thunkRequestManager(`/b/${buildingId}/issue/solution`,
        {
            issueIndex,
            name,
            description,
            price
        }, callback)
}

export function postNewVoting(buildingId, voting, callback) {
    return thunkRequestManager(`/b/${buildingId}/voting`, voting, callback)
}

export function postManualVoting(buildingId, unitId, votedFor, callback) {
    return thunkRequestManager(`/b/${buildingId}/voting/manual-submit/${unitId}/${votedFor}`, null, callback)
}

export function postVote(buildingId, vote, callback) {
    return thunkRequestTenant(`/${buildingId}/voting/submit`, vote, callback, "POST")
}

export function postVotingKey(publicKey, callback) {
    return thunkRequestTenant('/info/public-key', { publicKey }, callback, "POST")
}

export function postNewDocument(buildingId, name, link, callback) {
    return thunkRequestManager(`/b/${buildingId}/docs`,
        {
            "buildingId": buildingId,
            "name": name,
            "link": link
        }, callback)
}

export function deleteDocument(buildingId, doc) {
    return thunkRequestManager( `/b/${buildingId}/docs`,
        { buildingId, doc },
        null,
        "DELETE",
        true
    )
}

export function postLogin(username, password, isManager, callback) {

    //NOTE: jwt is not important for this route, it could be used thunkRequestTenant 
    return thunkRequestManager(isManager ? '/auth/login-manager' : '/auth/login',
        {
            username,
            password
        }, callback, "POST", false)(null)
}

export function getBuilding(buildingId, callback) {
    return thunkRequestTenant('/' + buildingId + "/building",
        null, callback,
        "GET", false)(null);
}

//TODO: Consider caching, forcing request

// export function getTenantNotification(buildingId) {
//     return getBuilding(buildingId)
// }

// export function getTenantActivities(buildingId) {
//     return getBuilding(buildingId)
// }

export function getTenentVoting(buildingId, callback) {
    return thunkRequestTenant('/' + buildingId + '/voting', null, callback, "GET", false)(null)
}

export function getTenantInfo(callback) {
    return thunkRequestTenant('/info', null, callback, "GET")(null)
}

export function getAllUsers(callback) {
    return thunkRequestTenant('/tenants', null, callback, "GET");
}

// export function getTenentInvoices(buildingId) {
//     return getBuilding(buildingId)
// }

// export function getTenantDocs(buildingId) {
//     return getBuilding(buildingId)
// }

// export function getTenantBuildingFinance(buildingId) {
//     return getBuilding(buildingId)
// }

export function getPreRegisterData(token, callback) {
    //TODO: check if thunk is suffecient
    //NOTE: jwt is not relevant for this request
    thunkRequestManager('/auth/pre-register/' + token,
        null, callback
        ,
        "GET", false)(null)
}

export function registerUser(token, data, callback) {
    //TODO: check if thunk is needed
    thunkRequestManager('/auth/register/' + token,
        data, callback
        ,
        "POST", false)(null)
}

export function confirmUserUnit(buildingId, unitId, userId) {
    return thunkRequestManager(`/b/${buildingId}/auth/confirm-user-unit/${unitId}/${userId}`, {}, null, "POST", true)
}

export function assignUserUnit(buildingId, unitId, userId, data) {
    return thunkRequestManager(`/b/${buildingId}/auth/assign-user-unit/${unitId}/${userId}`, data, null, "POST", true)
}

export function setUnitOwner(buildingId, unitId, data) {
    return thunkRequestManager(`/b/${buildingId}/auth/set-unit-owner/${unitId}`, data, null, "POST", true)
}

export function postInflow(buildingId, invoiceId, payer, amount, callback) {
    return dispatch => {
        fetchRequestManager("/finance/inflow",
            (err, data, dispatch) => {
                if (callback) { callback() }
                //TODO: consider this
                fetchBuildings()(dispatch);
            },
            dispatch,
            "POST",
            {
                "buildingId": buildingId,
                "invoiceId": invoiceId,
                // "payer": payer,
                "amount": amount
            }
        )
    };
}

export function postInflowRevert(buildingId, invoiceId, payer, amount, callback) {
    return dispatch => {
        fetchRequestManager("/finance/inflow-revert",
            (err, data, dispatch) => {
                if (callback) { callback() }
                //TODO: consider this
                fetchBuildings()(dispatch);
            },
            dispatch,
            "POST",
            {
                "buildingId": buildingId,
                "invoiceId": invoiceId,
                // "payer": payer,
                "amount": amount
            }
        )
    };
}

export function postOutflow(buildingId, payableId, amount, reason, callback) {
    return dispatch => {
        fetchRequestManager("/finance/outflow",
            (err, data, dispatch) => {
                if (callback) { callback() }
                //TODO: consider this
                fetchBuildings()(dispatch);
            },
            dispatch,
            "POST",
            {
                "buildingId": buildingId,
                "reason": reason,
                // "payer": payer,
                "amount": amount,
                "payableId": payableId
            }
        )
    };
}

export function settleUnsettled(buildingId) {
    return thunkRequestManager("/finance/settle-unsettled", { buildingId }, null, "POST", true)
}




////////////////////////////////////////
//TODO: consider not using reducer
export function editSolution(buildingId, issueIndex, solutionIndex, data) {
    let address = `/b/${buildingId}/issue/solution/${issueIndex}/${solutionIndex}`
    return thunkRequestManager(address,
        data, (err, data, dsp) => {
            if (err) {
                if (err.status === 401) {
                    dsp(genericAction(UNAUTHENTICATED))
                }
                console.error("Can not get update solution")
                return
            }
        },
        "PUT", true)
}

export function deleteSolution(buildingId, data) {
    let address = `/b/${buildingId}/issue/solution/`
    return thunkRequestManager(address,
        data, (err, data, dsp) => {
            if (err) {
                if (err.status === 401) {
                    dsp(genericAction(UNAUTHENTICATED))
                }
                console.error("Can not delete solution")
                return
            }
        },
        "PUT", true)
}

export function editIssue(buildingId, issueIndex, data) {
    let address = `/b/${buildingId}/issue/${issueIndex}`
    return thunkRequestManager(address,
        data, (err, data, dsp) => {
            if (err) {
                if (err.status === 401) {
                    dsp(genericAction(UNAUTHENTICATED))
                }
                console.error("Can not get update solution")
                return
            }
        },
        "PUT", true)
}

export function deleteIssue(buildingId, issueId) {
    let address = `/b/${buildingId}/issue/${issueId}`
    return thunkRequestManager(address,
        null, (err, data, dsp) => {
            if (err) {
                if (err.status === 401) {
                    dsp(genericAction(UNAUTHENTICATED))
                }
                console.error("Can not get update solution")
                return
            }
        },
        "DELETE", true)
}

export function moveUpIssue(buildingId, issueIndex, issueId) {
    let address = `/b/${buildingId}/issue-move-up/${issueIndex}/${issueId}`
    return thunkRequestManager(address,
        null, (err, data, dsp) => {
            if (err) {
                if (err.status === 401) {
                    dsp(genericAction(UNAUTHENTICATED))
                }
                console.error("Can not move issue")
                return
            }
        },
        "PUT", true)
}



///////////////////
//TODO: add UI success notification
//TODO: add error handling
//NETWORK - Data Caching... TBD...

