import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PayableStatus } from "../BuildingInvoices/PayableStatus";
import { Card } from "../Card/Card";
import { TabWrapper } from "../TabWrapper/TabWrapper";
import { PaymentOrder } from "./PaymentOrder";

const classes = {
    tableContainer: {
        minHeight: '150px',
        position: 'relative'
    },
    tableHead: {
        '& .MuiTableCell-root': {
            color: '#64748b',
            fontWeight: 'bold',
            paddingBlock: '8px',
            paddingInline: '0px',
        }
    },
    tableBody: {
        '& .MuiTableCell-root': {
            paddingInline: '0px',
        }
    },
    bolder: {
        fontWeight: 'bolder'
    },
    status: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    noRecords: {
        position: 'absolute',
        top: `60%`,
        left: '45%'
    }

} as const;

type Test = {
    invoices: any[],
    bankAccount: any,
    lastUnpaid: any,
    payReference: string
}

export const TenantInvoices = ({ building, unit }: { building: any, unit: any }) => {

    const getInfo = useCallback((): Test => {
        let invoices = unit.invoices || [];
        let bankAccount = (building.finance || {}).bankAccount;
        let lastUnpaid = invoices.find((x: any) => x.status !== "paid") || {};
        let payReference = lastUnpaid.invoiceId || (invoices[invoices.length - 1] || {}).invoiceId;

        return { invoices, bankAccount, lastUnpaid, payReference };
    }, [building.finance, unit.invoices]);

    const { t, i18n } = useTranslation();
    const [info, setInfo] = useState<Test>(getInfo());


    useEffect(() => {
        setInfo(getInfo());
    }, [unit, building, getInfo]);

    return (
        <TabWrapper header="My Account">
            <Card>
                <TableContainer sx={classes.tableContainer}>
                    <Table size="medium">
                        <TableHead>
                            <TableRow sx={classes.tableHead}>
                                <TableCell align="left">{t("Invoice Id")}</TableCell>
                                <TableCell align="center">{t("Period")}</TableCell>
                                <TableCell align="center">{t("Total")}</TableCell>
                                <TableCell align="center">{t("Status")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {info.invoices.map((invoice: any) => (
                                <TableRow key={invoice._id} sx={classes.tableBody}>
                                    <TableCell align="left">{invoice.invoiceId}</TableCell>
                                    <TableCell align="center">{t("formatDate", { date: new Date(invoice.periodStart) }) + " - " + t("formatDate", { date: new Date(invoice.periodEnd) })} </TableCell>
                                    <TableCell align="center" sx={classes.bolder}>{t("priceFormat", { num: invoice.total })} </TableCell>
                                    <TableCell sx={classes.status}><PayableStatus status={invoice.status === "paid" || invoice.type === "fund"} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {info.invoices.length === 0 && <Typography sx={classes.noRecords} >{t('No records')}</Typography>}
                </TableContainer>
            </Card>
            {i18n.language === 'sr' &&
                <PaymentOrder
                    recipient={building.name}
                    debit={unit.debit}
                    bankAccount={info.bankAccount}
                    payReference={info.payReference} />
            }
        </TabWrapper>
    );
}