import { TabWrapper } from "../TabWrapper/TabWrapper";
import { DocumentsList } from "./DocumentsList";

export const Documents = ({ documents }: { documents: any }) => {

    return (
        <TabWrapper header="Documents">
            <DocumentsList documents={documents} />
        </TabWrapper>
    );
}