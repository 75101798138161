import { Box, Typography } from "@mui/material"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next";
import './TabWrapper.css'

const classes = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '50px',
        minHeight: '100%'
    },
    header: {
        fontWeight: 600,
        fontSize: '1.6em'
    }
} as const;

export const TabWrapper = ({ header, children, onClick }: { header: string, children?: ReactNode, onClick?: (e: any) => void }) => {

    const { t } = useTranslation();

    return (
        <Box sx={classes.wrapper} onClick={(e) => { if (!!onClick) { onClick(e) } }}>
            <Typography variant='h5' sx={classes.header}>{t(header)}</Typography>
            {children}
        </Box>
    )
}