import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import nacl from "tweetnacl";
import { getTenentVoting, postVote } from "../../actions/data-manager";
import { VotingEntity } from "../../internal-types";
import { keyToBase64, PRIVATE_KEY } from "../register/RegisterForm";
import { Warning } from "./Warning";
import { Buffer } from "buffer";
import { TabWrapper } from "../TabWrapper/TabWrapper";
import { Card } from "../Card/Card";
import { getBuildingId } from "../Application";
import { useTranslation } from "react-i18next";

const classes = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '50px',
    },
    header: {
        fontWeight: 600,
        fontSize: '1.6em',
    },
    subheader: {
        color: 'gray',
        fontSize: '0.9rem'
    },
    subject: {
        fontSize: '1.2rem'
    },
    button: {
        marginLeft: 'auto',
        borderRadius: '50px'
    }
} as const;

export const Voting = (): JSX.Element => {

    const [status, setStatus] = useState();
    const [voting, setVoting] = useState<VotingEntity>();
    const [selectedCandidateId, setSelectedCandidateId] = useState('');
    const [errorText, setErrorText] = useState('');
    const [privateKey] = useState(localStorage.getItem(PRIVATE_KEY));

    const { t } = useTranslation();

    useEffect(() => {
        getTenentVoting(getBuildingId(), (err: any, res: any, disp: any) => {
            setStatus(res.status || "not allowed");
            setVoting(res);
        })
    }, []);

    const handleVote = () => {
        let voteData = { votingId: voting!._id, votedFor: selectedCandidateId, time: new Date() };
        let voteDataStr = JSON.stringify(voteData);
        let dataBuffer = Buffer.from(voteDataStr);

        let buffKey = Buffer.from(privateKey!, 'base64');
        const signatureRaw = nacl.sign.detached(dataBuffer, buffKey);
        let signature = keyToBase64(signatureRaw);

        let vote = { vote: voteDataStr, signature };

        postVote(getBuildingId(), vote, (err: any, data: any) => {
            if (err) {
                if (err.status === 406) {
                    setErrorText(t("Signature not valid") + "!");
                } else {
                    setErrorText(err.errorMessage);
                }
            } else {
                setStatus(data.status)
            }

        })(null);
    }

    return (<>
        {
            !!voting &&
            <TabWrapper header="Voting">
                {!!privateKey &&
                    <>
                        {status === "not allowed" && <Warning text="You account isn't confirmed as owner of any unit. Please contact your building manager" />}
                        {status !== "not allowed" &&
                            <Card>
                                <Typography sx={classes.subheader}>{t('Current voting')}</Typography>
                                {status === "no voting" && <Typography>{t('There is no active voting at the moment')}.</Typography>}
                                {status === "in progress" &&
                                    <>
                                        <Typography sx={classes.subject}>{voting.subject}</Typography>
                                        <FormControl>
                                            <FormLabel>{t('Options')}</FormLabel>
                                            <RadioGroup value={selectedCandidateId} onChange={(event) => { setSelectedCandidateId(event.target.value) }}>
                                                {voting.candidates.map((candidate, ind: number) => (<FormControlLabel value={candidate._id} control={<Radio size="small" />} label={candidate.name} key={ind} />))}
                                            </RadioGroup>
                                        </FormControl>
                                        <Button variant='contained' sx={classes.button} disabled={selectedCandidateId === ''} onClick={handleVote}>{t("Vote")}</Button>
                                        {!!errorText && <Typography color='error'>{errorText}</Typography>}
                                    </>
                                }
                                {status === "voted" && <Typography>{t('Thank you for voting')}. </Typography>}
                            </Card>
                        }
                    </>
                }
                {
                    !privateKey && <Warning text={"Voting is disabled because private key isn't saved in application. Please, set up key in settings"} />
                }
            </TabWrapper>
        }
    </>
    );
}