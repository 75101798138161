import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { WarningIcon } from "../icons/WarningIcon";

const classes = {
    wrapper: {
        padding: '20px',
        backgroundColor: '#FFCC00',
        borderRadius: '10px',
        '& .MuiTypography-root': {
            fontWeight: 'bolder'
        },
        display: 'flex',
        alignItems: 'center',
        gap: '20px'
    }
} as const;

export const Warning = ({ text }: { text: string }) => {

    const { t } = useTranslation();

    return (
        <Box sx={classes.wrapper}>
            <WarningIcon />
            <Typography>{t(text)}.</Typography>
        </Box>
    );
}