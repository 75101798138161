import { TabWrapper } from "../TabWrapper/TabWrapper";
import { BuildingInvoicesList } from "./BuildingInvoicesList";

export const BuildingInvoices = ({ buildingFinance }: { buildingFinance: any }) => {

    return (
        <TabWrapper header='Building finance'>
            <BuildingInvoicesList buildingFinance={buildingFinance} />
        </TabWrapper>
    );
}