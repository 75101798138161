import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabWrapper } from "../TabWrapper/TabWrapper";
import { Issue } from "./Issue";

export const Issues = ({ issues }: { issues: any }) => {

    const { t } = useTranslation();

    return (
        <TabWrapper header="Issues">
            {
                issues.map((issue: any, index: number) => (
                    <Issue issue={issue} key={index} />
                ))
            }
            {issues.length === 0 && <Typography>{t("Currently there are not notifications for this unit")}.</Typography>}
        </TabWrapper>
    );
}