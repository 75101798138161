import { Box, Button, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { LoginInfo } from "../internal-types";
import { postLogin } from "../actions/data-manager";
import { JWT_TENANT } from "../actions/network-manager";
import { useNavigate } from "react-router-dom";

const classes = {
    wrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '20px'
    },
    title: {
        fontWeight: 'bold',
    },
    textField: {
        '& label.Mui-focused': {
            color: 'rgb(30, 41, 60)'
        },
        '& .MuiOutlinedInput-root': {
            '& input': {
                zIndex: '5'
            },
            '& fieldset': {
                borderColor: 'rgb(203, 213, 224)',
                backgroundColor: 'white'
            },
            '&:hover fieldset': {
                borderColor: 'rgb(203, 213, 224)',
            },
            '&.Mui-focused': {
                '& fieldset': {
                    borderColor: 'rgb(203, 213, 224)'
                }
            },
        }
    },
    select: {
        '& label.Mui-focused': {
            color: 'red'
        }
        ,
        '& fieldset': {
            borderColor: 'rgb(203, 213, 224)',
            backgroundColor: 'white',
            zIndex: 0
        },
        '& svg': {
            zIndex: 2
        },
        '& div': {
            zIndex: 3
        },

    },
    loginButton: {
        alignSelf: 'flex-end',
        '&.MuiButton-root': {
            borderRadius: 40,
            padding: '10px',
            paddingInline: '25px'
        }
    }
} as const;

export const LoginForm = () => {

    const [loginInfo, setLoginInfo] = useState<LoginInfo>({ username: '', password: '' });

    const navigate = useNavigate();

    useEffect(() => {
        if (!!localStorage.getItem(JWT_TENANT)) {
            navigate('../tenant');
        }
    }, [navigate]);

    const loginCallback = (err: any, res: any, dsp: any) => {
        if (err) {
            localStorage.removeItem(JWT_TENANT)
            return;
        }
        localStorage.setItem(JWT_TENANT, res);

        navigate('../tenant/overview');
    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            postLogin(loginInfo.username, loginInfo.password, false, loginCallback);
        }
    }

    return (
        <Box style={classes.wrapper} onKeyUp={handleKeyUp}>
            <Typography sx={classes.title} variant='h4'>Login</Typography>
            <TextField
                autoFocus
                variant="outlined"
                size='small'
                label="Username"
                sx={classes.textField}
                value={loginInfo.username}
                onChange={(e) => {
                    setLoginInfo({ ...loginInfo, username: e.target.value })
                }}
            />
            <TextField
                variant="outlined"
                size='small'
                type="password"
                label="Password"
                sx={classes.textField}
                value={loginInfo.password}
                onChange={(e) => {
                    setLoginInfo({ ...loginInfo, password: e.target.value })
                }}
            />
            <Button
                sx={classes.loginButton}
                variant='contained'
                onClick={(event) => {
                    event.preventDefault();
                    postLogin(
                        loginInfo.username,
                        loginInfo.password,
                        false,
                        loginCallback)
                }}
            >Login</Button>
        </Box>)
}