import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";

const classes = {
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px'
    },
    expandWrapper: {
        marginLeft: 'auto',
        transition: 'transform .25s',
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px'
    },
    subheader: {
        color: 'gray',
        fontSize: '0.9rem'
    },
    title: {
        fontSize: '1.2rem'
    },
} as const;

export const Notification = ({ title, description }: { title: string, description: string }) => {

    const { t } = useTranslation();

    return (
        <Card>
            <Typography sx={classes.title}>{title}</Typography>
            <Box sx={classes.description}>
                <Typography sx={classes.subheader}>{t("Description")}</Typography>
                <Typography>{description}</Typography>
            </Box>
        </Card>
    );
}