import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from '../locale/en.json';
import sr from '../locale/sr.json';

const resources = {
  en,
  sr,
};
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    debug: true,
    fallbackLng: 'sr',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
      format: (data: any, format: any, language: any) => {
        if (format === 'intlDate')
          return new Intl.DateTimeFormat(language).format(data); // -> "12/20/2012" if run in en-US locale with time zone America/Los_Angeles

        if (format === 'DD/MM/YYYY')
          return data.getDate() + "." + (data.getMonth() + 1) + "." + data.getFullYear() + '.'

        if (format === 'num2format')
          return new Intl.NumberFormat(language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data) + ' RSD';

        if (format === 'nalog')
          return new Intl.NumberFormat(language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data);

        return ''
      }
    },
  });
export default i18next;