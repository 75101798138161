import { Box, Divider, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { ApplicationContext } from "../Application";
import { Card } from "../Card/Card";
import { PaymentOrderItem } from "./PaymentOrderItem";

const classes = {
    title: {
        marginLeft: 'auto'
    },
    mainContainer: {
        display: 'flex',
        alignItems: 'baseline'
    },
    itemsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        paddingBlock: '20px',
        gap: '5px'
    },
    divider: {
        marginInline: '10px'
    },
    itemsGroup: {
        display: 'flex',
        gap: '10px',
        alignItems: 'flex-end'
    }

} as const;

export const PaymentOrder = ({ recipient, debit, bankAccount, payReference }: { recipient: string, debit: number, bankAccount: string, payReference: string }) => {

    const { t } = useTranslation();
    const { tenantInfo } = useContext(ApplicationContext);
    const qrCode = `K:PR|V:01|C:1|R:${bankAccount}|N:${recipient}|I:RSD${(debit > 0 ? debit : 0).toFixed(2).toString().replace('.', ',')}` +
        `|P:${tenantInfo.firstName} ${tenantInfo.lastName}|SF:289|S:Po nalogu gradjana|RO:00${payReference.replaceAll('-', '')}`;

    return (
        <Card>
            <Typography sx={classes.title}>Nalog za uplatu</Typography>
            <Box sx={classes.mainContainer}>
                <Box sx={classes.itemsContainer}>
                    <PaymentOrderItem flex={1} label="Uplatilac" size="large" value={`${tenantInfo.firstName} ${tenantInfo.lastName}`} />
                    <PaymentOrderItem flex={1} label="Svrha uplate" size="large" />
                    <PaymentOrderItem flex={1} label="Primalac" size="large" value={recipient} />
                </Box>
                <Divider sx={classes.divider} orientation="vertical" variant="middle" flexItem />
                <Box sx={classes.itemsContainer}>
                    <Box sx={classes.itemsGroup}>
                        <PaymentOrderItem flex={1} label='sifra placanja' size="small" value='221' />
                        <PaymentOrderItem flex={1} label='valuta' size="small" value="RSD" />
                        <PaymentOrderItem flex={4} label='iznos' size="small" value={t("nalog", { num: (debit > 0 ? debit : 0) })} />
                    </Box>
                    <PaymentOrderItem flex={1} label='Racun primaoca' size="small" value={bankAccount} />
                    <Box sx={classes.itemsGroup}>
                        <PaymentOrderItem flex={1} label='model' size="small" value='00' />
                        <PaymentOrderItem flex={4} label='poziv na broj' size="small" value={payReference.replaceAll('-', '')} />
                    </Box>
                    <div style={{ marginLeft: "auto", maxWidth: 80 }}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={qrCode}
                            viewBox={`0 0 256 256`}
                        />

                    </div>
                </Box>
            </Box>
        </Card>
    );
}