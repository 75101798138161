import { Box } from "@mui/material";

const classes = {
    green: {
        backgroundColor: '#bbf7d0',
        color: '#16693f',
        paddingBlock: '5px',
        paddingInline: '15px',
        borderRadius: '50px',
        fontWeight: 'bolder',
        boxSizing: 'border-box',
        fontSize: '0.8em'
    },
    red: {
        backgroundColor: '#fecaca',
        color: '#9c1c60',
        paddingBlock: '5px',
        paddingInline: '15px',
        borderRadius: '50px',
        fontWeight: 'bolder',
        boxSizing: 'border-box',
        fontSize: '0.8em',
        display: 'inline'

    }
} as const;

export const PayableStatus = ({status}: {status: boolean}) => {
    return (<Box sx={status ? classes.green : classes.red}>{status ? 'PLAĆENO' : 'NIJE PLAĆENO'}</Box>);
};