import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";
import { DownloadIcon } from "../icons/DownloadIcon";
import { LinkIcon } from "../icons/LinkIcon";

const classes = {
    tableContainer: {
        minHeight: '150px',
        position: 'relative'
    },
    tableHead: {
        '& .MuiTableCell-root': {
            color: '#64748b',
            fontWeight: 'bold',
            paddingBlock: '8px',
            paddingInline: '0px',
        }
    },
    tableBody: {
        '& .MuiTableCell-root': {
            paddingInline: '0px',
        }
    },
    bolder: {
        fontWeight: 'bolder'
    },
    status: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    noRecords: {
        position: 'absolute',
        top: `60%`,
        left: '45%'
    }

} as const;

export const DocumentsList = ({ documents }: { documents: any }) => {

    const { t } = useTranslation();

    return (
        <Card>
            <TableContainer sx={classes.tableContainer}>
                <Table size="medium">
                    <TableHead>
                        <TableRow sx={classes.tableHead}>
                            <TableCell align="left">{t("Name")}</TableCell>
                            <TableCell align="center">{t("Date")}</TableCell>
                            <TableCell align="center">{t("Action")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((payable: any) => (
                            <TableRow key={payable._id} sx={classes.tableBody}>
                                <TableCell align="left">{payable.name} </TableCell>
                                <TableCell align="center">{t("formatDate", { date: new Date(payable.created) })}</TableCell>
                                <TableCell align="center"><a href={payable.link}>{(payable.link.includes('dropboxusercontent')) ? <DownloadIcon /> : <LinkIcon />} </a></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {documents.length === 0 && <Typography sx={classes.noRecords} >{t('No documents')}</Typography>}
            </TableContainer>
        </Card>
    );
}