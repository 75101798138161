import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router";
import { JWT_TENANT } from "../actions/network-manager";

export const ProtectedRoute = ({ children }: { children: ReactNode }): JSX.Element => {

    const location = useLocation();
    const isAuthenticated = !!localStorage.getItem(JWT_TENANT);

    if (isAuthenticated) {
        return <>{children}</>
    } else {
        return <Navigate to={{ pathname: '/app/login' }} state={{ from: location }} replace />;
    }
}