import { useNavigate } from 'react-router-dom';
import { JWT_TENANT } from '../../actions/network-manager';
import './Icons.css';

export const LogoutIcon = () => {

    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem(JWT_TENANT);
        navigate('../login');
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icons-stroke logout" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} onClick={logout}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
        </svg>
    );
}