import { Box, Collapse, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";
import { ExpandIcon } from "../icons/ExpandIcon";

const classes = {
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px'
    },
    expandWrapper: {
        marginLeft: 'auto',
        transition: 'transform .25s',
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px'
    },
    subheader: {
        color: 'gray',
        fontSize: '0.9rem'
    },
    title: {
        fontSize: '1.2rem'
    },
} as const;

export const Issue = ({ issue }: { issue: any }) => {

    const [expand, setExpand] = useState(false);
    const { t } = useTranslation();

    return (
        <Card>
            <Box sx={classes.header}>
                <Typography sx={classes.title}>{issue.title}</Typography>
                <Box sx={{ ...classes.expandWrapper, transform: `rotate(${expand ? 180 : 0}deg)`, width: '20px' }} onClick={() => { setExpand(!expand) }}>
                    <ExpandIcon />
                </Box>
            </Box>
            <Box sx={classes.description}>
                <Typography sx={classes.subheader}>{t("Description")}</Typography>
                <Typography>{issue.description}</Typography>
            </Box>
            <Collapse in={expand} timeout={250}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <Divider />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <Typography sx={classes.subheader}>{t("Solutions")}</Typography>
                        {issue.solutions.map((solution: any, ind: number) => (
                            <Box key={ind}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography >{`${ind + 1}. ${solution.name}`}</Typography>
                                    <Typography sx={{ marginLeft: 'auto', fontWeight: 'bold' }}>{`${t("priceFormat", { num: (solution.price) })}`}</Typography>
                                </Box>
                                <Typography sx={{ paddingLeft: '10px', fontSize: '15px' }}>{solution.description || t('Description was not provided.')}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Collapse>
        </Card>
    );
}