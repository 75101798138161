import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SubMenu } from "../../internal-types";

const classes = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        gap: '5px',
    },
    itemTitle: {
        color: 'rgb(24, 95, 167)',
        paddingInline: '10%',
    },
    itemsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: '15px',
        alignItems: 'center',
        paddingInline: '10%',
        paddingBlock: '2.5px',
        boxSizing: 'border-box',
        borderRadius: '5px',
        userSelect: 'none',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(150, 150, 150, 0.2)'
        },
        '& .MuiTypography-root': {
            color: 'white'
        },
    },
    selectedItem: {
        backgroundColor: 'rgba(200, 200, 200, 0.3)',
        '& .MuiTypography-root': {
            color: 'white',
            fontWeight: 'bold'
        },
        '&:hover': {
            backgroundColor: 'rgba(200, 200, 200, 0.3)'
        },
        '& .icons-stroke': {
            stroke: 'white'
        }
    }
} as const;

export const SideMenuItem = ({ content, selectedItem, changeSelectedItem }: { content: SubMenu, selectedItem: string, changeSelectedItem: (item: string) => void }) => {

    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    return (
        <Box sx={classes.wrapper}>
            <Typography sx={classes.itemTitle}> {(t(content.title).toUpperCase())}</Typography>
            {content.items.map((item, ind) => (
                <Box sx={selectedItem === item.path ? { ...classes.itemsWrapper, ...classes.selectedItem } : classes.itemsWrapper} key={ind} onClick={() => {
                    if (!!item.path) {
                        navigate(item.path);
                        changeSelectedItem(item.path);
                    } else {
                        i18n.changeLanguage(i18n.language === 'en' ? 'sr' : 'en');
                    }
                }}>
                    {!!item.icon && item.icon}
                    <Typography>{t(item.title)}</Typography>
                </Box>
            ))}
        </Box>
    );
}