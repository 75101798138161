import { Box, InputAdornment, MenuItem, Select } from "@mui/material";
import ApartmentIcon from '@mui/icons-material/Apartment';

const classes = {
    mainContainer: {
        marginLeft: 'auto',
        marginRight: '30px'
    }
} as const;

export const UnitSelect = ({ units, selectedUnit, onUnitChange }: { units: any[], selectedUnit: any, onUnitChange?: (unit: any) => void }) => {

    return (
        <Box sx={classes.mainContainer}>
            <Select
                size="small"
                variant='standard'
                value={(!!selectedUnit && selectedUnit._id) || ''}
                label="Age"
                onChange={(e) => { if (!!onUnitChange) onUnitChange(e.target.value) }}
                startAdornment={<InputAdornment position="start">
                    <ApartmentIcon />
                </InputAdornment>}
            >
                {units.map((unit: any, ind: number) => (<MenuItem value={unit._id} key={ind}>{unit.address}</MenuItem>))}
            </Select>
        </Box>);

}