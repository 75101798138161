import { HamburgerIcon } from "../icons/HamburgerIcon";
import './NavBar.css';
import { UnitSelect } from "./UnitSelect";

export const NavBar = ({ toggleSideMenu, units, selectedUnit, onUnitChange }: { toggleSideMenu: (open: boolean) => void, units: any, selectedUnit: string, onUnitChange?: (unit: any) => void }) => {
    return (
        <div className="navbar">
            <HamburgerIcon toggleSideMenu={toggleSideMenu} />
            <UnitSelect units={units} selectedUnit={selectedUnit} onUnitChange={onUnitChange} />
        </div>
    );
}