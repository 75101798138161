import { Box, Button, Checkbox, Fade, FormControlLabel, Popper, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { getTenantInfo, postVotingKey } from "../../actions/data-manager";
import { Card } from "../Card/Card";
import { CheckMark } from "../icons/CheckMark";
import { keyToBase64, PRIVATE_KEY, PUBLIC_KEY } from "../register/RegisterForm";
import { TabWrapper } from "../TabWrapper/TabWrapper";
import { Buffer } from "buffer";
import nacl, { SignKeyPair } from "tweetnacl";
import { HelperIcon } from "../icons/HelperIcon";
import { useTranslation } from "react-i18next";

const classes = {
    votingSeetings: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
    },
    header: {
        color: 'gray',
        fontSize: '0.9rem'
    },
    button: {
        marginLeft: 'auto',
        borderRadius: '50px'
    },
    popperWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    helperCloud: {
        width: '300px',
        backgroundColor: 'rgba(255,255,255,1)',
        padding: 2,
        borderRadius: 5,
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        userSelect: 'none'
    },
    helperText: {
        color: 'rgb(30, 41, 60)',
        fontSize: '0.8rem'
    },

} as const;

export const Settings = () => {

    const [privateKey, setPrivateKey] = useState<string | null>(localStorage.getItem(PRIVATE_KEY));
    const [publicKey, setPublicKey] = useState<string | null>(localStorage.getItem(PUBLIC_KEY));
    const [newPrivateKey, setNewPrivateKey] = useState('');
    const [helperText, setHelperText] = useState('');
    const [keyPair, setKeyPair] = useState<SignKeyPair | undefined>();
    const [privateKeyWritten, setPrivateKeyWritten] = useState(false);
    const [toggleHelp, setToggleHelp] = useState(false);
    const helperRef = useRef<SVGSVGElement>(null);

    const { t } = useTranslation();

    useEffect(() => {
        getTenantInfo((err: any, user: any) => {
            if (!!user.votingKey && (!publicKey || publicKey !== user.votingKey)) {
                localStorage.setItem(PUBLIC_KEY, user.votingKey);
                setPublicKey(user.votingKey);
            }

            if (!user.votingKey) {
                localStorage.removeItem(PUBLIC_KEY);
                localStorage.removeItem(PRIVATE_KEY);
                setPublicKey(null);
                setPrivateKey(null);
            }
        });
    }, []);

    const verifyKey = () => {
        try {
            let buf = Buffer.from(newPrivateKey, 'base64');
            let keyPair = nacl.sign.keyPair.fromSecretKey(buf);

            if (keyToBase64(keyPair.publicKey) === publicKey) {
                setPrivateKey(newPrivateKey);
                localStorage.setItem(PRIVATE_KEY, newPrivateKey);
                setHelperText('');
            }
        } catch (error) {
            setHelperText(t('Neispravan kljuc') + '!');
        }
    }

    const generateKeys = useCallback(() => {
        setKeyPair(nacl.sign.keyPair());
    }, []);

    const saveKeys = useCallback((publicKeyU8: Uint8Array, privateKeyU8: Uint8Array) => {
        let publicKeyBase64 = keyToBase64(publicKeyU8);
        let privateKeyBase64 = keyToBase64(privateKeyU8);
        postVotingKey(publicKeyBase64, (err: any, data: any) => {
            if (!err) {
                localStorage.setItem(PUBLIC_KEY, publicKeyBase64)
                if (!!privateKeyBase64) {
                    localStorage.setItem(PRIVATE_KEY, privateKeyBase64)
                    setPrivateKey(privateKeyBase64);
                }
                setPublicKey(publicKeyBase64);
            } else {
                if (err.status === 412) {
                    alert(t("Not allowed already set key, contact your building manager"))
                }
            }
        })(null);
    }, []);

    const handleIconClick = () => {
        setToggleHelp(!toggleHelp);
    }

    const handleClickOutside = (e: any) => {
        if (helperRef.current && !helperRef.current.contains(e.target)) {
            setToggleHelp(false);
        }
    }


    return (
        <TabWrapper header="Settings" onClick={handleClickOutside}>
            <Card>
                {
                    !publicKey &&
                    <>
                        <Typography sx={classes.header}>{t('Voting')}</Typography>
                        <Typography>{t("If you want to participate in voting you need to generate voting keys and save them in application")}</Typography>
                        {!!keyPair &&
                            <>
                                <Box>
                                    <Typography>{t('Private key')}</Typography>
                                    <TextField
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        value={keyToBase64(keyPair.secretKey)}
                                        disabled
                                        minRows={1}
                                        maxRows={2}
                                        multiline

                                    />
                                </Box>

                                <Box sx={classes.popperWrapper}>
                                    <FormControlLabel
                                        label="Zapisao/la sam privatan kljuc"
                                        control={<Checkbox checked={privateKeyWritten} onChange={(event) => setPrivateKeyWritten(event.target.checked)} />}
                                    />
                                    <HelperIcon helperRef={helperRef} handleClick={handleIconClick} />
                                    <Popper open={toggleHelp} anchorEl={helperRef.current} placement='top' disablePortal transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Box sx={classes.helperCloud}>
                                                    <Typography sx={classes.helperText}>{t("In case you lose access to device, this key will be required to participate in voting")}!</Typography>
                                                </Box>
                                            </Fade>
                                        )}
                                    </Popper>
                                </Box>
                            </>
                        }
                        <Button
                            variant='contained'
                            sx={classes.button}
                            onClick={() => {
                                if (!keyPair) {
                                    generateKeys()
                                }
                                else {
                                    saveKeys(keyPair.publicKey, keyPair.secretKey)
                                }
                            }}
                            disabled={!!keyPair && !privateKeyWritten} >{!keyPair ? 'Generisi' : 'Sacuvaj'}</Button>
                    </>
                }
                {!!publicKey &&
                    <>
                        {!!privateKey &&
                            <Box sx={classes.votingSeetings}>
                                <Box sx={classes.textWrapper}>
                                    <Typography sx={classes.header}>{t('Voting')}</Typography>
                                    <Typography>{t("Private key successfully set in application")}.</Typography>
                                </Box>
                                <CheckMark />
                            </Box>
                        }
                        {
                            !privateKey &&
                            <>
                                <Typography sx={classes.header}>{t('Voting')}</Typography>
                                <Typography>{t("In order to participate in voting, please enter your private key")}.</Typography>
                                <TextField
                                    error={!!helperText}
                                    helperText={helperText}
                                    onChange={(e) => {
                                        setNewPrivateKey(e.target.value);
                                    }} size='small' />
                                <Button variant='contained' sx={classes.button} onClick={verifyKey}>{t('Confirm')}</Button>
                            </>
                        }
                    </>
                }
            </Card>

        </TabWrapper>
    );
}