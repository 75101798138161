import { Box } from "@mui/material"
import { Navigate, Route, Routes } from "react-router-dom";
import { BuildingInvoices } from "./BuildingInvoices/BuildingInvoices";
import { Documents } from "./Documents/Documents";
import { Issues } from "./Issues/Issues";
import { Notifications } from "./Notifications/Notifications";
import { Settings } from "./Settings/Settings";
import { TenantInvoices } from "./TenantInvoices/TenantInvoices";
import { Voting } from "./Voting/Voting";

const classes = {
    wrapper: {
        backgroundColor: 'rgb(241, 245, 249)',
        minHeight: '95vh',
        width: '100%',
        boxSizing: 'border-box',
        paddingBlock: '3%',
        paddingInline: '11%',
        '@media (max-width: 800px)': {
            paddingInline: '5%',
        }
    }
} as const;

export const ApplicationTab = ({ building, selectedUnit }: { building: any, selectedUnit: any }) => {

    return (
        <Box sx={classes.wrapper}>
            <Routes>
                <Route path='overview' element={<div>Hello from overview</div>} />
                <Route path='notifications' element={<Notifications notifications={building.notifications} />} />
                <Route path='issues' element={<Issues issues={building.issues} />} />
                <Route path='voting' element={<Voting />} />
                <Route path='invoices' element={<TenantInvoices building={building} unit={selectedUnit}/>} />
                <Route path='building-invoices' element={<BuildingInvoices buildingFinance={building.finance} />} />
                <Route path='documents' element={<Documents documents={building.docs.all || []} />} />
                <Route path='settings' element={<Settings />} />
                <Route path='*' element={<Navigate replace to="overview" />} />
            </Routes>
        </Box>
    )
}