import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { CopyIcon } from "../icons/CopyIcon";

const classes = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none'
    },
    valueContainer: {
        border: '1px solid #64748b',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '0.5rem',
        position: 'relative',
        boxSizing: 'border-box'
    },
    clipboard: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.15)'
        },
        left: 0
    }
} as const;

export const PaymentOrderItem = ({ flex, label, value, size }: { flex: number, label: string, value?: string, size: string }) => {

    const [hovered, setHovered] = useState(false);

    return (
        <Box sx={{ flex, ...classes.mainContainer }}>
            <Typography variant={size === 'large' ? 'body2' : 'caption'}>{label}</Typography>
            <Box
                sx={{ minHeight: size === 'large' ? '40px' : '25px', ...classes.valueContainer }}
                onMouseEnter={() => { setHovered(true); }}
                onMouseLeave={() => { setHovered(false); }}
            >
                {hovered && value && <Button sx={classes.clipboard} onClick={() => { navigator.clipboard.writeText(value) }}> <CopyIcon /> </Button>}
                <Typography sx={{ fontWeight: 'bolder' }}>{value}</Typography>
            </Box>
        </Box>
    );
}