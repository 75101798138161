import { TextField, Box, Button, Select, FormControl, InputLabel, MenuItem, Typography, FormControlLabel, Checkbox, Collapse, Popper, Fade } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import nacl from "tweetnacl";
import { getPreRegisterData, registerUser } from "../../actions/data-manager";
import { RegistrationInfo } from "../../internal-types";
import { Buffer } from "buffer";
import { HelperIcon } from "../icons/HelperIcon";

export const PUBLIC_KEY = 'publicKey'
export const PRIVATE_KEY = "privateKey"

const classes = {
    wrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '20px'
    },
    title: {
        fontWeight: 'bold',
    },
    nameWrapper: {
        display: 'flex',
        gap: '20px',
        '& .MuiFormControl-root': {
            flex: 1
        },
        "@media (max-width: 800px)": {
            flexDirection: 'column'
        },
    },
    textField: {
        '& label.Mui-focused': {
            color: 'rgb(30, 41, 60)'
        },
        '& .MuiOutlinedInput-root': {
            '& input': {
                zIndex: '5'
            },
            '& fieldset': {
                borderColor: 'rgb(203, 213, 224)',
                backgroundColor: 'white'
            },
            '&:hover fieldset': {
                borderColor: 'rgb(203, 213, 224)',
            },
            '&.Mui-focused': {
                '& fieldset': {
                    borderColor: 'rgb(203, 213, 224)'
                }
            },
        }
    },
    select: {
        '& label.Mui-focused': {
            color: 'red'
        }
        ,
        '& fieldset': {
            borderColor: 'rgb(203, 213, 224)',
            backgroundColor: 'white',
            zIndex: 0
        },
        '& svg': {
            zIndex: 2
        },
        '& div': {
            zIndex: 3
        },


    },
    menuPaper: {
        maxHeight: '250px'
    },
    voting: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    digitalVoting: {
        color: 'rgb(30, 41, 60)'
    },
    popperWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    helperCloud: {
        width: '300px',
        backgroundColor: 'rgba(255,255,255,1)',
        padding: 2,
        borderRadius: 5,
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        userSelect: 'none'
    },
    helperText: {
        color: 'rgb(30, 41, 60)',
        fontSize: '0.8rem'
    },
    registerButton: {
        alignSelf: 'flex-end',
        '&.MuiButton-root': {
            borderRadius: 40,
            padding: '10px',
            paddingInline: '25px',
            '&:disabled': {
                backgroundColor: 'lightgray'
            }
        }
    }
} as const;

export function keyToBase64(key: Uint8Array) {
    return Buffer.from(key).toString('base64');
}

export const RegisterForm = (): JSX.Element => {

    // TODO Add proper frontend validation

    let navigate = useNavigate();

    let { buildingId } = useParams();

    const [buildingUnits, setBuildingUnits] = useState([]);
    const [registerInfo, setRegisterInfo] = useState<RegistrationInfo>(
        { firstName: '', lastName: '', email: '', username: '', password: '', mobile: '', pendingBuildings: { id: buildingId!, units: [] } });
    const [errorField, setErrorField] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [digitalVoting, setDigitalVoting] = useState(true);
    const [privateKeyWritten, setPrivateKeyWritten] = useState(false);
    const [keyPair] = useState(nacl.sign.keyPair());
    const [toggleHelp, setToggleHelp] = useState(false);
    const helperRef = useRef(null);

    const handleIconClick = () => {
        setToggleHelp(!toggleHelp);
    }

    const registerCallBack = (err: any, data: any, dsp: any) => {

        if (!!err) {
            if (err.wrongField) {
                setErrorField(err.wrongField);
                setErrorMessage(err.errorMessage);
            }
            return;
        }

        if (digitalVoting) {
            localStorage.setItem(PUBLIC_KEY, keyToBase64(keyPair.publicKey));
            localStorage.setItem(PRIVATE_KEY, keyToBase64(keyPair.secretKey));
        }

        navigate('../../login', { replace: true });
    }

    useEffect(() => {
        getPreRegisterData(buildingId, (err: any, data: any, dsp: any) => {
            if (err) {
                navigate('../register/error', { replace: true });
                return;
            }
            setBuildingUnits(data.units);
        });
    }, [buildingId, navigate]);

    useEffect(() => {
        if (digitalVoting) {
            setRegisterInfo({ ...registerInfo, publicKey: keyToBase64(keyPair.publicKey) });
        } else {
            setRegisterInfo({ ...registerInfo, publicKey: undefined });
        }
    }, [digitalVoting])

    const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            registerUser(buildingId, registerInfo, registerCallBack);
        }
    }

    return (
        <>
            {buildingUnits &&
                <Box style={classes.wrapper} onKeyUp={handleKeyUp} onClick={() => {
                    if (toggleHelp) {
                        setToggleHelp(false);
                    }
                }}>
                    <Typography sx={classes.title} variant='h4'>Create Account</Typography>
                    <Box sx={classes.nameWrapper}>
                        <TextField
                            hiddenLabel
                            variant="outlined"
                            size='small'
                            label="First name"
                            sx={classes.textField}
                            value={registerInfo.firstName}
                            onChange={(e) => {
                                setRegisterInfo({ ...registerInfo, firstName: e.target.value })
                            }}
                            error={"firstName" === errorField}
                            helperText={"firstName" === errorField ? errorMessage : null}
                            autoFocus
                        />
                        <TextField
                            hiddenLabel
                            variant="outlined"
                            size='small'
                            label="Last name"
                            sx={classes.textField}
                            value={registerInfo.lastName}
                            onChange={(e) => {
                                setRegisterInfo({ ...registerInfo, lastName: e.target.value })
                            }}
                            error={"lastName" === errorField}
                            helperText={"lastName" === errorField ? errorMessage : null}
                        />
                    </Box>

                    <Box sx={classes.nameWrapper}>
                        <TextField
                            variant="outlined"
                            size='small'
                            label="Email"
                            sx={classes.textField}
                            value={registerInfo.email}
                            onChange={(e) => {
                                setRegisterInfo({ ...registerInfo, email: e.target.value })
                            }}
                            error={"email" === errorField}
                            helperText={"email" === errorField ? errorMessage : null}
                        />
                        <TextField
                            variant="outlined"
                            size='small'
                            label="Mobile number"
                            type="number"
                            sx={classes.textField}
                            value={registerInfo.mobile}
                            onChange={(e) => {
                                setRegisterInfo({ ...registerInfo, mobile: e.target.value })
                            }}
                            error={"mobile" === errorField}
                            helperText={"mobile" === errorField ? errorMessage : null}
                        />

                    </Box>
                    <Box sx={classes.nameWrapper}>
                        <TextField
                            variant="outlined"
                            size='small'
                            label="Username"
                            sx={classes.textField}
                            value={registerInfo.username}
                            onChange={(e) => {
                                setRegisterInfo({ ...registerInfo, username: e.target.value })
                            }}
                            error={"username" === errorField}
                            helperText={"username" === errorField ? errorMessage : null}
                        />
                        <TextField
                            variant="outlined"
                            size='small'
                            label="Password"
                            type="password"
                            sx={classes.textField}
                            value={registerInfo.password}
                            onChange={(e) => {
                                setRegisterInfo({ ...registerInfo, password: e.target.value })
                            }}
                        />
                    </Box>
                    <FormControl fullWidth sx={{ maxHeight: '50px' }} size="small" onKeyUp={(e) => { e.stopPropagation(); }}>
                        <InputLabel>Select your units</InputLabel>
                        <Select
                            label="Select your units"
                            sx={classes.select}
                            value={registerInfo.pendingBuildings.units}
                            multiple
                            MenuProps={{ sx: classes.menuPaper }}
                            onChange={(e) => {
                                let units: string[] = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
                                setRegisterInfo({ ...registerInfo, pendingBuildings: { ...registerInfo.pendingBuildings, units } })
                            }}
                            error={"units" === errorField}
                        >
                            {buildingUnits.map((unit: any, index) => <MenuItem value={unit} key={index}>{unit.address}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Box sx={classes.voting}>
                        <FormControlLabel
                            label="Želim da koristim digitalno glasanje u aplikaciji"
                            control={<Checkbox checked={digitalVoting} onChange={(event) => setDigitalVoting(event.target.checked)} />}
                            sx={classes.digitalVoting}
                        />

                        <Collapse in={digitalVoting} >
                            <TextField
                                variant="outlined"
                                size='small'
                                fullWidth
                                value={keyToBase64(keyPair.secretKey)}
                                disabled
                                minRows={2}
                                maxRows={3}
                                multiline

                            />


                            <Box sx={classes.popperWrapper}>
                                <FormControlLabel
                                    label="Zapisao/la sam privatan kljuc"
                                    control={<Checkbox checked={privateKeyWritten} onChange={(event) => setPrivateKeyWritten(event.target.checked)} />}
                                />
                                <HelperIcon helperRef={helperRef} handleClick={handleIconClick} />
                                <Popper open={toggleHelp} anchorEl={helperRef.current} placement='top' disablePortal transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Box sx={classes.helperCloud}>
                                                <Typography sx={classes.helperText}>U slučaju da izgubite pristup ovom uređaju, ovaj ključ će vam biti potreban kako bi na novom uređaju mogli da učestvujete u glasanju!</Typography>
                                            </Box>
                                        </Fade>
                                    )}
                                </Popper>
                            </Box>
                        </Collapse>
                    </Box>
                    <Button
                        sx={classes.registerButton}
                        variant='contained'
                        disabled={digitalVoting && !privateKeyWritten}
                        onClick={() => {
                            registerUser(buildingId, registerInfo, registerCallBack);
                        }}
                    >Register</Button>
                </Box>
            }
        </>);
};