import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabWrapper } from "../TabWrapper/TabWrapper";
import { Notification } from "./Notification";

export const Notifications = ({ notifications }: { notifications: any }) => {

    const { t } = useTranslation();

    return (
        <TabWrapper header="Notifications">
            {
                notifications.map((notification: any, index: number) => (
                    <Notification title={notification.title} description={notification.description} />
                ))
            }
            {notifications.length === 0 && <Typography>{t("Currently there are not notifications for this unit")}.</Typography>}
        </TabWrapper>);
}